import * as React from 'react';
import { useEffect } from 'react';
import { useExternalScript } from '../../utils/useExternalScript';
import {isMobile} from 'react-device-detect';
import './style.scss';
import './resize.scss';

declare global {
    interface Window {
        AF_SMART_SCRIPT: any;
    }
}

export const Header = React.memo(() => {
    const externalScript = 'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js';
    const state = useExternalScript(externalScript);
    useEffect(() => {
        if (state === 'ready') {
            const oneLinkURL = "https://puzzle-breakers.onelink.me/gLFv";
            const mediaSource = {keys:["utm_source","pid"],defaultValue:"website"};
            const googleClickIdKey = "af_sub5";
            const afSub5 = {keys:["fbclid","af_sub5"]};
            const campaign = {keys:["utm_campaign","c"],defaultValue:"default"};
            const channel = {keys:["utm_medium","af_channel"]};
            const adSet = {keys:["utm_content","af_adset"]};
            const ad = {keys:["utm_term","af_ad"]};
            const deepLinkValue = {keys:["dl"]};
            const custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};
            const afSub1 = {keys:["af_sub1"]};
            const afSub2 = {keys:["af_sub2"]};
            const afSub3 = {keys:["af_sub3"]};
            const afSub4 = {keys:["af_sub4"]};

            if (window.AF_SMART_SCRIPT) {
                const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
                    oneLinkURL: oneLinkURL,
                    afParameters: {
                        mediaSource: mediaSource,
                        campaign: campaign,
                        channel: channel,
                        adSet: adSet,
                        ad: ad,
                        afSub1: afSub1,
                        afSub2: afSub2,
                        afSub3: afSub3,
                        afSub4: afSub4,
                        afSub5: afSub5,
                        googleClickIdKey: googleClickIdKey,
                        deepLinkValue: deepLinkValue,
                        afCustom: [
                            custom_ss_ui
                        ]
                    }
                });

                if (result) {
                    const result_url = result.clickURL;

                    if (isMobile) {
                        if (document.getElementById('andrd_link')) {
                            // @ts-ignore
                            document.getElementById('andrd_link').setAttribute('href', result_url);
                        }
                        if (document.getElementById('ios_link')) {
                            // @ts-ignore
                            document.getElementById('ios_link').setAttribute('href', result_url);
                        }
                    }

                    window.AF_SMART_SCRIPT.displayQrCode('my_qr_code_div_id');
                    if (window.AF_SMART_SCRIPT.fireImpressionsLink) {
                        window.AF_SMART_SCRIPT.fireImpressionsLink();
                    }
                }
            }
        }
    }, [state]);

    return (
        <div className={ 'header' }>
            <div className="left_content">
                <div className="logo">
                    <img src="/img/pb_logo.png" alt=""/>
                </div>
                <div className="qr" id="my_qr_code_div_id"/>
                <div className="buttons">
                    <div className="google">
                        <a href="//play.google.com/store/apps/details?id=com.playrix.puzzlebreakers" id="andrd_link"><img
                            src="/img/google.svg" alt=""/></a>
                    </div>
                    <div className="ios">
                        <a href="//apps.apple.com/us/app/puzzle-breakers-rpg-online/id1523290976" id="ios_link"><img
                            src="/img/ios.svg" alt=""/></a>
                    </div>
                </div>
                <div className="play_now">
                    Play now
                </div>
            </div>
        </div>
    );
});
