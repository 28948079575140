import React from 'react';
import { Header } from "./components/Header";
import { Content } from "./components/Content";
import Footer from "./components/Footer";
import Redelf from "./components/Redelf";
import './style.scss';
import './resize.scss';

function App() {
  return (
      <div className="App">
        <Header/>
        <Content/>
        <Footer/>
        <Redelf/>
      </div>
  );
}

export default App;
