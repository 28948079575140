import * as React from 'react';
import './style.scss';
import './resize.scss';

class Footer extends React.Component {
  public render() {
    return (
        <div className={'footer'}>
          <div className="inner">
            <div className="cols">
              <div className="col"></div>
              <div className="col">
                <div className={'title'}>BE IN THE CENTER<br/>OF BREAKERS</div>
                <div className="text">
                  To stay in touch and always aware of Breakers follow us in social media. For latest info about the world of Breakers and sneak peeks on what's next join the conversation on our forum.
                  <div className="uppercased">
                    Follow puzzle breakers on
                  </div>
                  <div className="icons">
                    <div className="fb"><a href="//facebook.com/PuzzleBreakers/"><img src="/img/f_logo.svg" alt=""/></a></div>
                    <div className="discourse"><a href="//discord.com/invite/HjWjEa8saM"><img src="/img/discord_logo.svg" alt=""/></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Footer;
