import * as React from 'react';
import { useEffect } from 'react';
import { useExternalScript } from '../../utils/useExternalScript';
import './style.scss';
import './resize.scss';
import { isMobile } from 'react-device-detect';

export const Content = React.memo(() => {
    const externalScript = 'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js';
    const state = useExternalScript(externalScript);
    useEffect(() => {
        if (state === 'ready' && isMobile) {
            const oneLinkURL = "https://puzzle-breakers.onelink.me/gLFv";
            const mediaSource = {keys:["utm_source","pid"],defaultValue:"website"};
            const googleClickIdKey = "af_sub5";
            const afSub5 = {keys:["fbclid","af_sub5"]};
            const campaign = {keys:["utm_campaign","c"],defaultValue:"default"};
            const channel = {keys:["utm_medium","af_channel"]};
            const adSet = {keys:["utm_content","af_adset"]};
            const ad = {keys:["utm_term","af_ad"]};
            const deepLinkValue = {keys:["dl"]};
            const custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};
            const afSub1 = {keys:["af_sub1"]};
            const afSub2 = {keys:["af_sub2"]};
            const afSub3 = {keys:["af_sub3"]};
            const afSub4 = {keys:["af_sub4"]};

            if (window.AF_SMART_SCRIPT) {
                const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
                    oneLinkURL: oneLinkURL,
                    afParameters: {
                        mediaSource: mediaSource,
                        campaign: campaign,
                        channel: channel,
                        adSet: adSet,
                        ad: ad,
                        afSub1: afSub1,
                        afSub2: afSub2,
                        afSub3: afSub3,
                        afSub4: afSub4,
                        afSub5: afSub5,
                        googleClickIdKey: googleClickIdKey,
                        deepLinkValue: deepLinkValue,
                        afCustom: [
                            custom_ss_ui
                        ]
                    }
                });

                if (result) {
                    const result_url = result.clickURL;

                    if (document.getElementById('andrd_link2')) {
                        // @ts-ignore
                        document.getElementById('andrd_link2').setAttribute('href', result_url);
                    }
                    if (document.getElementById('ios_link2')) {
                        // @ts-ignore
                        document.getElementById('ios_link2').setAttribute('href', result_url);
                    }
                    if (window.AF_SMART_SCRIPT.fireImpressionsLink) {
                        window.AF_SMART_SCRIPT.fireImpressionsLink();
                    }
                }
            }
        }
    }, [state]);
    return (
        <div className={ 'content' }>
            <div className="inner">
                <div className="cols">
                    <div className="col">
                        <div className={ 'title' }>EPIC STRATEGY<br/>PUZZLE COMBAT</div>
                        <div className={ 'text' }>
                            Puzzle Breakers is a brand-new fantasy tactical puzzle game. Dark Lord and his evil minions
                            are at the gate, show them Glory
                            of the true King! Collect and upgrade hundreds of heroes, craft epic gear and earn legendary
                            magical artifacts. Defeat Dark
                            Lord in a thrilling campaign, fight against terrifying Giants with your clan and become the
                            Lord of Arena in Raids against
                            other players.
                        </div>
                        <div className="buttons">
                            <div className="google">
                                <a href="//play.google.com/store/apps/details?id=com.playrix.puzzlebreakers"
                                   id="andrd_link2"><img src="/img/google.svg" alt=""/></a>
                            </div>
                            <div className="ios">
                                <a href="//apps.apple.com/us/app/puzzle-breakers-rpg-online/id1523290976"
                                   id="ios_link2"><img
                                    src="/img/ios.svg" alt=""/></a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="phone">
                            <video playsInline autoPlay muted loop className="video-item">
                                <source type={ 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' }
                                        src={ '/video/land_video_eng.mp4' }/>
                            </video>
                            <img src="/img/phone.png?v=2" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
