import { useEffect, useState } from 'react';

export const useExternalScript = (url:any) => {
    let [state, setState] = useState(url ? 'loading' : 'idle');

    useEffect(() => {
        if (!url) {
            setState('idle');
            return;
        }

        let script = document.querySelector(`script[src="${ url }"]`);

        const handleScript = (e:any) => {
            setState(e.type === 'load' ? 'ready' : 'error');
        };

        if (!script) {
            script = document.createElement('script') as any;
            // @ts-ignore
            script.type = 'application/javascript';
            // @ts-ignore
            script.src = url;
            // @ts-ignore
            script.async = true;
            // @ts-ignore
            document.body.appendChild(script);
            // @ts-ignore
            script.addEventListener('load', handleScript);
            // @ts-ignore
            script.addEventListener('error', handleScript);
        }

        script?.addEventListener('load', handleScript);
        script?.addEventListener('error', handleScript);

        return () => {
            script?.removeEventListener('load', handleScript);
            script?.removeEventListener('error', handleScript);
        };
    }, [url]);

    return state;
};
