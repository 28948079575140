import * as React from 'react';
import './style.scss';
import './resize.scss';

class Redelf extends React.Component {
  public render() {
    return (
        <div className="redelf">
          <div className="inner">
            <img src="/img/hero_down.png" alt=""/>
          </div>
        </div>
    );
  }
}

export default Redelf;
